<template>
	<div class="h-100">
		<router-view v-if="isLanguageLoaded"/>
	</div>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { getDirection } from '@/utils'
import moment from 'moment';

export default {
	name: 'App',
	data () {
		return {
			user: false,
		}
	},
	created () {
		localStorage.setItem('apiURL', process.env.VUE_APP_API_URL);
	},
	computed: {
		isLanguageLoaded: function() {
			return this.$i18n.availableLocales.length > 0;
		},
		...mapState({
			menuState: 'menu',
		}),
		...mapGetters({
			currentUser: 'currentUser'
		})
	},
	watch: {
		// watch for currentUser rights, redirect to home if no rights (anymore) to view current page
		currentUser: {
			handler(user) {
				const requiredMenu = this.$route?.meta?.menu_rights;
				if (requiredMenu && !(user && user.rights?.menu?.[requiredMenu])) {
					this.$router.push('/');
				}
			},
			immediate: true
		}
	},
	methods: {
		...mapActions(['logout']),
	},
	beforeMount () {
		const direction = getDirection()
		if (direction.isRtl) {
			document.body.classList.add('rtl')
			document.dir = 'rtl'
			document.body.classList.remove('ltr')
		} else {
			document.body.classList.add('ltr')
			document.dir = 'ltr'
			document.body.classList.remove('rtl')
		}
	},
	mounted () {
		if (this.$route?.name && !this.menuState?.menu?.map(m => m.name)?.includes(this.$route.name)) {
			this.$router.push('/');
		}
	},
}
</script>
